import { useEffect, useState } from 'react';

export const useGPTScript = (condition = false) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!condition || loaded) return;

    const scriptSrc = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

    if (document.querySelector(`script[src="${scriptSrc}"]`)) {
      setLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    script.defer = true;
    script.onload = () => setLoaded(true);

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [condition]);

  return loaded;
};
