import React from 'react';
import PropTypes from 'prop-types';
import HomePage from '../components/Views/HomePage';
import { getSeoData } from '../utils/helpers';
import { keywordsQuery } from '../queries';
import Layout from '../components/Layout/Layout';
import initApollo from '../utils/init-Apollo';
import { getStaticData } from '../utils/helpers/getStaticData';

const App = props => <HomePage {...props} />;

export const getStaticProps = async ({ locale, ...rest }) => {
  const apolloClient = initApollo({}, { lang: locale });

  try {
    const [{ keywordsData }, staticData, seoData] = await Promise.all([
      apolloClient.query({ query: keywordsQuery }),
      getStaticData(apolloClient, true),
      getSeoData(apolloClient)
    ]);

    return {
      props: {
        seoData,
        keywords: keywordsData?.keywords || [],
        apolloCache: apolloClient.extract(),
        ...staticData
      },
      revalidate: 86400
    };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {
      props: {
        seoData: {},
        keywords: [],
        categories: [],
        citiesUnPopularList: [],
        regions: [],
        error: JSON.stringify(error)
      }
    };
  }
};

App.propTypes = {
  statusCode: PropTypes.number,
  seoData: PropTypes.object,
  keywords: PropTypes.array,
  categories: PropTypes.array,
  citiesUnPopularList: PropTypes.array,
  regions: PropTypes.object,
  error: PropTypes.any
};

App.getLayout = (page, staticData) => (
  <Layout isHomePage={true} pageProps={staticData} isBanner isBackgroundBanner>
    {page}
  </Layout>
);

export default App;
