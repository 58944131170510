import React, { useState, memo, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useQuery } from '@apollo/client';
import { Trans, useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { featuredRowQuery, getInitialItems } from '../../../queries';
import useBugsnagNotifier from '../../../hooks/useBugsnagNotifier';
import { INITIAL_SEARCH_FORM_VALUES } from '../../../utils/helpers/constants';
import { NewSearch } from '../../NewSearch';
import { ResidenceCard } from '../../Cards/ResidencesCard';
import { PopularRequests } from '../../PopularRequests';
import { SectionPlaceholder } from '../../Placeholders/SectionPlaceholder';
import { ItemCardPlaceholder } from '../../Placeholders/ItemCardPlaceholder';
import { withEndlessScroll } from '../../HOC/endlessScroll';
import { FallbackView } from '../../FallbackView';
import ErrorIcon from '../../../public/static/images/search/icons/icon-error.svg';
import { ItemCard } from '../../ui/ItemCard';
import { useBookmarks } from '../../../hooks/useBookmarks';
import { CardsLayout } from '../../ui/CardsLayout';
import { HomeLayoutWithBanner } from '../../HomeLayoutWithBanner';
import { NewSection } from '../../NewSection';
import { PremiumTitle } from '../../NewSectionHeader/styled';
import useScreenSize from '../../../hooks/useScreenSize';
import { useGPTScript } from '../../../hooks/useGPTScript';

const Banner = dynamic(() => import('../../Banner'), { ssr: false });
const AdManagerBanner = dynamic(() => import('../../Banner/AdManagerBanner'), {
  ssr: false
});

const COUNT_OF_LOADING_ITEMS = 24;
let reciviesItems = COUNT_OF_LOADING_ITEMS;

const featuredListRef = React.createRef<HTMLDivElement>();
const EndlessFeaturedItems = withEndlessScroll(CardsLayout);

const HomePage = ({ keywords, categories }) => {
  const { t, i18n } = useTranslation();
  const [isPopState, setIsPopState] = useState(false);
  const router = useRouter();
  const { isBookmarked } = useBookmarks();
  const { isSmallDesktop, isLarge, isSmall } = useScreenSize();

  useEffect(() => {
    router.beforePopState(({ options }) => {
      // eslint-disable-next-line no-param-reassign
      options.scroll = false;
      window.isPopState = true;

      return true;
    });
    setIsPopState(window.isPopState);

    return () => {
      window.isPopState = false;
    };
  }, []);

  /*
   * Load  data via hooks
   * */
  const {
    loading: initialItemsLoading,
    data: initialItemsData,
    error: initialItemsError
  } = useQuery(getInitialItems, { ssr: false });

  const initialFeaturedVariables = { limit: COUNT_OF_LOADING_ITEMS, offset: 0 };
  const {
    loading: featuredQueryLoading,
    data: featuredQueryData,
    error: featuredQueryError,
    fetchMore
  } = useQuery(featuredRowQuery(initialFeaturedVariables), {
    variables: initialFeaturedVariables,
    ssr: false
  });

  /*
   * Agencies List
   * */
  const agenciesLimit = isSmallDesktop || isLarge ? 16 : 8;
  const agenciesItems = (initialItemsData?.agencies || []).slice(
    0,
    agenciesLimit
  );
  const agenciesList = agenciesItems
    .filter(a => a.items.length > 0)
    .map(agency => (
      <ItemCard
        key={agency.items[0].id}
        cypress="agency-card-link"
        hasHorizontalScroll
        {...agency.items[0]}
        inBookmarks={isBookmarked(agency.items[0].id)}
        isBusiness
      />
    ));

  /*
   * Residences List
   * */
  const residences = initialItemsData?.residences || [];
  const residencesList =
    residences &&
    residences.map(residence => (
      <ResidenceCard
        key={residence.id}
        {...residence}
        cypress="residence-card-link"
        hideExcessItems={true}
      />
    ));

  /*
   * Featured List
   * */
  const items = featuredQueryData?.items || [];

  const onLoadMoreFeaturedItems = async () => {
    const currentLength = items.length;
    const variables = {
      limit: COUNT_OF_LOADING_ITEMS,
      offset: currentLength
    };

    fetchMore({
      query: featuredRowQuery(variables),
      variables,
      notifyOnNetworkStatusChange: true
    }).then(fetchMoreResult => {
      reciviesItems = fetchMoreResult.data.items.length;
    });
  };

  const itemsList = items.map(item => (
    <ItemCard
      key={item.id}
      {...item}
      cypress="featured-card-link"
      cypress-card="featured-card"
      inBookmarks={isBookmarked(item.id)}
      stat="featured-card-link"
    />
  ));

  useGPTScript(!isPopState && !!itemsList.length && isSmall);

  const itemsWithBanner = itemsList.reduce((list, currItem, currIndex) => {
    const isBannerPosition =
      currIndex === 9 || (currIndex > 9 && (currIndex - 9) % 30 === 0);

    const bottomBanner = (
      <React.Fragment key={`bottom-banner-${currIndex}`}>
        <Banner
          tagName={'p'}
          containerClassName={'lotriver-bottom-banner content-banner'}
          containerId={`js-lotriver-bottom-banner-${currIndex}`}
          sid={'220686'}
          bn={'6'}
          arCn={Number(currIndex)}
        />
        <AdManagerBanner bannerName={'bottom'} tagName={'p'} />
      </React.Fragment>
    );

    return isBannerPosition && !isPopState
      ? [...list, currItem, bottomBanner]
      : [...list, currItem];
  }, []);

  const Error = () => (
    <FallbackView
      Icon={ErrorIcon}
      width={50}
      height={50}
      text={t('search.error.text')}
      title={t('search.error.title')}
      btnText={t('search.error.link')}
      onClickHandler={() => router.reload()}
    />
  );

  const SkeletonLoaders = () => (
    <SectionPlaceholder isCardsLayout noHeader={true}>
      {Array.from({ length: 4 }, (_, index) => (
        <ItemCardPlaceholder key={index} isCardsLayout />
      ))}
    </SectionPlaceholder>
  );

  useBugsnagNotifier(initialItemsError);
  useBugsnagNotifier(featuredQueryError);

  useEffect(() => {
    return () => {
      reciviesItems = COUNT_OF_LOADING_ITEMS;
    };
  }, []);

  const getItemsListContent = (hasError, list, hasHorizontalScroll = false) =>
    hasError ? (
      <Error />
    ) : (
      <CardsLayout hasHorizontalScroll>{list}</CardsLayout>
    );

  const featuredItemsContent = featuredQueryError ? (
    <Error />
  ) : (
    <EndlessFeaturedItems
      items={itemsWithBanner}
      loading={featuredQueryLoading}
      ref={featuredListRef}
      isStopLoad={reciviesItems < COUNT_OF_LOADING_ITEMS}
      onLoadMore={onLoadMoreFeaturedItems}
    >
      {itemsWithBanner}
    </EndlessFeaturedItems>
  );

  const hasKeywords = !!keywords?.length && i18n.language !== 'ru';

  return (
    <HomeLayoutWithBanner>
      <NewSearch
        initialFormValues={INITIAL_SEARCH_FORM_VALUES}
        isHomePage
        categories={categories}
      />
      <NewSection
        id="home-page-residences"
        title={t('sections.residences.title')}
        url="/yasayis-kompleksleri"
        asLink="/yasayis-kompleksleri"
        urlTitle={t('sections.residences.show_all')}
        cypress="residences-section-title"
      >
        {!initialItemsLoading ? (
          getItemsListContent(initialItemsError, residencesList)
        ) : (
          <SkeletonLoaders />
        )}
      </NewSection>

      {!isPopState && !!itemsList.length && isSmall && (
        <>
          <Banner
            key="lotriver-top-banner"
            containerClassName={'lotriver-top-banner'}
            containerId={'js-lotriver-top-banner'}
            sid={'220686'}
            bn={'2'}
            arCn={1}
          />
          <AdManagerBanner bannerName={'top'} />
        </>
      )}
      <NewSection
        id="home-page-agencies"
        title={t('sections.agencies.title')}
        url="/agentlikler"
        asLink="/agentlikler"
        urlTitle={t('sections.agencies.show_all')}
        cypress="agencies-section-title"
        hasHorizontalScroll={!!agenciesList.length && !initialItemsError}
      >
        {!initialItemsLoading ? (
          getItemsListContent(initialItemsError, agenciesList, true)
        ) : (
          <SkeletonLoaders />
        )}
      </NewSection>
      <NewSection
        id="home-page-featured"
        title={t('sections.featured.title')}
        url="/items/vipped"
        urlTitle={t('sections.vipped.show_all')}
        rightBlock={
          <PremiumTitle data-cy="featured-extra-title">
            <Trans i18nKey="sections.featured.right_text" />
          </PremiumTitle>
        }
        cypress="featured-section-title"
      >
        {!(featuredQueryLoading && !itemsList.length) ? (
          featuredItemsContent
        ) : (
          <SkeletonLoaders />
        )}
      </NewSection>
      {hasKeywords && <PopularRequests keywords={keywords} />}
    </HomeLayoutWithBanner>
  );
};

HomePage.propTypes = {
  error: PropTypes.object,
  categories: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.object)
};

export default memo(HomePage);
