import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopularRequestsList, PopularRequestsSection } from './styled';
import { NewSectionHeader } from '../NewSectionHeader';
import { KeywordsQuery } from '../../graphql/graphql';

export const PopularRequests: React.FC<{
  keywords: KeywordsQuery['keywords'];
}> = ({ keywords = [] }) => {
  const { t } = useTranslation();

  return (
    <PopularRequestsSection>
      <NewSectionHeader
        title={t('sections.popular_requests.title')}
        cypress="popular-requests-section-title"
      />
      <PopularRequestsList>
        {keywords.map(({ id, term, url }) => (
          <li key={id}>
            <a target="_blank" rel="noopener noreferrer" href={url || '#'}>
              {term}
            </a>
          </li>
        ))}
      </PopularRequestsList>
    </PopularRequestsSection>
  );
};
