import styled from 'styled-components';
import { FlexColumn } from '../../styled/common';

export const PopularRequestsSection = styled(FlexColumn)`
  box-sizing: border-box;
  background-color: #fff;
  margin-bottom: 20px;
`;

export const PopularRequestsList = styled('ul')`
  margin: 0;
  list-style: none;
  padding: 0 5px 0 15px;

  @media (min-width: ${({ theme }) => theme.tabletBreakpoint}) {
    padding: 0 40px 0 30px;
  }
  @media (min-width: ${({ theme }) => theme.desktopBreakpoint}) {
    padding: 0 20px 0 10px;
  }

  li {
    float: left;
    height: 30px;
    line-height: 30px;
    background: #fff;
    border: 1px solid ${({ theme: { colors } }) => colors.text.secondary};
    border-radius: 14px;
    margin: 10px 7px 0 0;
    padding: 0;

    &:hover {
      border-color: ${({ theme: { colors } }) => colors.systemColors.green};
    }
  }

  a {
    color: ${({ theme: { colors } }) => colors.text.secondary};
    font-size: 13px;
    padding: 0 8px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
  }
`;
